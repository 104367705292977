import React, { useState, useEffect } from 'react';
import Feed from "../components/Feed/Feed";
import Sidebar from "../components/Sidebar/Sidebar";
import Widgets from "../components/Widgets/Widgets";
 
const HomeFeed = (props) => {
  let user = props.user;

  return (
    <section>        
      <div className="app">
        <Sidebar user={user}/>

        <Feed user={user}/>

        <Widgets user={user}/>
    </div>
    </section>
  )
}
 
export default HomeFeed