import React, { useEffect, useState } from "react";
import "./Feed.css";
import Post from "./Post";
import TweetBox from "./TweetBox";
import { db } from "../../firebase";
import FlipMove from "react-flip-move";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { auth } from '../../firebase';

function Feed() {
  let params = useParams();
  let user = auth.currentUser;
  const [posts, setPosts] = useState([]);
  const postsRef = collection(db, "posts");

  console.log(user);

  useEffect(() => {
    if(user){
      const q = query(postsRef, where("user", "==", user.uid));  
      onSnapshot(q, (snapshot) => {
        setPosts(snapshot.docs.map((doc) => doc.data()));
      });  
    }
  }, [user]);

  console.log(posts);

  return (
    <div className="feed">
      <div className="feed__header">
        <h2>Home</h2>
      </div>

      <TweetBox user={user}/>

      <FlipMove>
        {posts.map((post) => (
          <Post
            key={post.text}
            displayName={post.displayName}
            username={post.username}
            verified={post.verified}
            text={post.text}
            avatar={post.avatar}
            image={post.image}
          />
        ))}
      </FlipMove>
    </div>
  );
}

export default Feed;
