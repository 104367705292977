import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: "Arial",
    body2: {
      fontFamily: "Arial",
      fontSize: "1.3rem"
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0A5784',
      ligth: '#9581d8'
    },
    secondary: {
      main: '#e8992e',
    },
    detail: {
      main: '#9581d8',
    },
  }
});

theme = responsiveFontSizes(theme);


export default theme;