// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBksC79k1Q3g4bLlTV9Npa4-FqMdLIiJbA",
  authDomain: "bravo-app-94dd3.firebaseapp.com",
  projectId: "bravo-app-94dd3",
  storageBucket: "bravo-app-94dd3.appspot.com",
  messagingSenderId: "571366784926",
  appId: "1:571366784926:web:93cad75640f16f0a01208f",
  measurementId: "G-0QV287LF4J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export {db, app}