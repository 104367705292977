import React, { useState } from "react";
import "./TweetBox.css";
import { Avatar, Button } from "@mui/material";
import { db } from "../../firebase";
import {collection, doc, addDoc } from "firebase/firestore"; 
import { auth } from '../../firebase';

function TweetBox() {
  const [tweetMessage, setTweetMessage] = useState("");
  const [tweetImage, setTweetImage] = useState("");

  const sendTweet = (e) => {
    e.preventDefault();
    console.log(tweetMessage);
    console.log(tweetImage);
      addDoc(collection(db, "posts"), {
        displayName: "Milleni Maya", //auth.currentUser.displayName,
        username: "milleni.maya", //auth.currentUser.username,
        text: tweetMessage,
        image: tweetImage,
        user: auth.currentUser.uid,
        groups: "groups/wkXk6f2qGAyw0ppun6tb",
        avatar: "https://i.pravatar.cc/150?img=10",
        //auth.currentUser.photoURL,
      });

    setTweetMessage("");
    setTweetImage("");
  };

  return (
    <div className="tweetBox">
      <form>
        <div className="tweetBox__input">
          <Avatar src="https://i.pravatar.cc/150?img=10" />
          <input
            onChange={(e) => setTweetMessage(e.target.value)}
            value={tweetMessage}
            placeholder="O que vamos divulgar?"
            type="text"
          />
        </div>
        <input
          onChange={(e) => setTweetImage(e.target.value)}
          value={tweetImage}
          className="tweetBox__imageInput"
          placeholder="Alguma imagem?"
          type="text"
        />

        <Button
          onClick={sendTweet}
          type="submit"
          className="tweetBox__tweetButton"
        >
          Bravo!
        </Button>
      </form>
    </div>
  );
}

export default TweetBox;
