import React from "react";
//@material-ui
import makeStyles from "@mui/styles/makeStyles";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'




const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    zIndex: 10,
    fontFamily: 'Ubuntu, Arial',
    color: "#000000",
  },
  children:{
    width: "70%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      width: "70%",
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
    },
    [theme.breakpoints.up('xl')]: {
      width: "70%",
    },
  },
  image: {
    margin: "0",
    maxHeight: "100%",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  },
  button:{
    fontFamily: 'Ubuntu, Arial',
    marginTop: theme.spacing(2),
    textTransform: "none",
    fontSize: "1rem",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "3rem",
    },
    '&:hover': {
      textTransform: "uppercase",
    }
  },
  homeTitle: {
    fontWeight: 800,
    lineHeight: 1,
    position: "relative",
    fontSize: "2rem",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: "3rem",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "4rem",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "5rem",
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
    },
  },
  logo: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "60%",
    },
    [theme.breakpoints.up('md')]: {
      width: "50%",
    },
  },
  homeSubTitle: {
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "2rem",
    },
  },
  homeDescription: {
    fontWeight: 500,
    textTransform: "uppercase",
    maxWidth: "60%",
    fontSize: "0.5rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1rem",
    },
  }
}));


function Home() {
  const classes = useStyles();
  const image1="images/home-back-1.svg" 
  const image2="images/home-back-2.svg" 

    return (
      <div
      className={classes.image}
      style={{
        backgroundImage: "url(" + image1 + "), url(" + image2 + ")",
        backgroundPosition: "left bottom, 120% 30%", backgroundSize: "30%, 30%"
        }}
      alt="background images" 
      id="image"
    >
      <div className={classes.children} id="children">
            <div className={classes.container} >
              <img src="/bravo-logo.png" alt="Bravo logo" className={classes.logo}/>
              <div className={classes.homeTitle}>Comunicação corporativa simples e inteligente</div>
              <Button 
                color="secondary" 
                size="large" 
                variant="contained"
                classes={{root: classes.button}}
                component={Link} 
                to="https:bravoi.com.br" target="_blank">CONHEÇA MAIS</Button>
            </div>
      </div>
    </div>
    );
  }

  
  export default Home;