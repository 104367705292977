import React from "react";
import "./Sidebar.css";
import SidebarOption from "./SidebarOption";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Button } from "@mui/material";

function Sidebar() {
  return (
    <div className="sidebar">
      <img src="/bravo-favicon.png" alt="Bravo logo" />

      <SidebarOption active Icon={HomeIcon} text="Home" Link={{pathname: "/"}}/>
      <SidebarOption Icon={NotificationsNoneIcon} text="Notificações" />
      <SidebarOption Icon={MailOutlineIcon} text="Messages" />
      <SidebarOption Icon={BookmarkBorderIcon} text="Comunidades" />
      <SidebarOption Icon={ListAltIcon} text="Treinamentos" />
      <SidebarOption Icon={PermIdentityIcon} text="Seu perfil" />
      <SidebarOption Icon={SearchIcon} text="Explore" />
    </div>
  );
}

export default Sidebar;
