import React from 'react';
import ReactDOM from 'react-dom';

// THEME
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
// App
import App from './App';

const render = Component => {
  ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Component />
        </MuiThemeProvider>,
    document.getElementById('root')
  );
};

render(App);

