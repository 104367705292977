import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import "./App.css";
import Signup from './pages/Signup';
import Login from './pages/Login';
import HomeFeed from './pages/HomeFeed';
import { BrowserRouter as Router} from 'react-router-dom';
import { auth } from './firebase';

function App() {
  const [user, setUser] = React.useState(auth.currentUser);
 
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" replace />;
    }
  
    return children;
  };

  return (
    <Router>
      <div>
        <section>                              
            <Routes>                                                                        
               <Route path="/" element={
                  <Login setUser={setUser} />
               }/>
               <Route path="/signup" element={
                  <Signup setUser={setUser} />
               }/>
               <Route path={`/feed/:user`} element={
                <ProtectedRoute user={user}>
                  <HomeFeed user={user}/>
                </ProtectedRoute>
               }/>
            </Routes>                    
        </section>
      </div>
    </Router>
  );
}

export default App;
